import React from 'react'
import { groq } from 'next-sanity'
import SanityPreview from '../components/SanityPreview'
import { getClient } from '../api/sanity'
import { IMAGE_FIELDS } from '../api'
import SEO from '../components/SEO'
import Container from '../components/Container'
import withErrorPage from '../hocs/withErrorPage'
import ArticlePromotion from '../components/ArticlePromotion'
import Section from '../components/Section'
import Hero from '../components/Hero'
import ArticleEntries from '../components/ArticleEntries'
import Collection from '../components/Collection'
import TagCloud from '../components/TagCloud'
import { NextRouterPreviewProps } from '../types/next-router-preview-props'
import { TagType } from '../components/Tags'
import { Blocks } from '../types/blocks'
import Tag from '../types/tag'
import { get } from 'lodash'
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { getIds } from '../helpers/getIds'
import setCacheKeys from '../server/helpers/setCacheKeys'
import generateRssFeed from '../utils/generateRSSFeed'

const frontPageQuery = groq`
  *[_type == 'frontpage']{
    ...,
    content[]{
      ...,
      tag->{
        _id,
        _type,
        name,
        "slug": slug.current,
      },
      reference->{
        _id,
        slug,
        title,
        preamble,
        mainImage${IMAGE_FIELDS},
        genre,
      },
      articles[]->{
        _id,
        slug,
        title,
        preamble,
        mainImage${IMAGE_FIELDS},
        genre,
      },
    },
    "latestTags": *[_type == "article" && count(tags)>0]|order(_publishedAt desc){tags}[0...50].tags[]->{
      _id,
      _type,
      name,
      "slug": slug.current
    }
  } | order(_updatedAt desc)[0]
`

interface Article {
  _id: string
  slug: { current: string; pretty: string }
  title: string
  preamble: Blocks
  strap: string
  mainImage: {
    _id: string
    asset: {
      altText: string
    }
  }
  genre: string
}

interface FrontpageBaseBlock {
  _key: string
}
interface Props {
  pageData: {
    _id: 'frontpage'
    content: Array<
      FrontpageBaseBlock &
        (
          | {
              _type: 'collection'
              tag?: Tag
              title: string
              layout: string
              articles: Article[]
            }
          | {
              _type: 'articles'
              layout: string
              articles: Article[]
            }
          | {
              _type: 'promotion'
              layout: string
              reference: Article
            }
          | {
              _type: 'articleEntries'
              articles: Article[]
            }
          | { _type: 'tagCloud' }
        )
    >
    latestTags: TagType[]
  }
  preview: boolean
}

const FrontPage: React.FC<Props & NextRouterPreviewProps> = ({ pageData, preview, router }) => {
  const data = pageData

  return (
    <SanityPreview query={frontPageQuery} initialData={pageData} preview={preview}>
      {() => (
        <>
          <SEO />
          <Container>
            {data.content.map((block) => {
              if (block._type === 'articles') {
                return (
                  <Section key={block._key}>
                    <ArticlePromotion {...block} />
                  </Section>
                )
              }

              if (block._type === 'promotion') {
                return (
                  <Section key={block._key}>
                    <Hero {...block} />
                  </Section>
                )
              }

              if (block._type === 'collection') {
                return (
                  <Section key={block._key}>
                    <Collection {...block} />
                  </Section>
                )
              }

              if (block._type === 'articleEntries') {
                return (
                  <Section key={block._key}>
                    <ArticleEntries {...block} promoted />
                  </Section>
                )
              }

              if (block._type === 'tagCloud') {
                if (pageData.latestTags?.length > 0) {
                  return (
                    <Section key={block._key} spacelessMobile>
                      <TagCloud latest={pageData.latestTags.filter(Boolean)} />
                    </Section>
                  )
                }

                return null
              }

              return `Ukjent blokk-type: '${get(block, '_type')}'`
            })}
          </Container>
        </>
      )}
    </SanityPreview>
  )
}

export async function getServerSideProps(
  ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<Props>> {
  const preview = !!ctx.preview
  const data = await getClient(preview).fetch(frontPageQuery)
  

  setCacheKeys(ctx, getIds(data))

  return {
    props: { pageData: data, preview },
  }
}

export default withErrorPage(FrontPage)
